export default {
  name: 'CustomerDialogEdit',
  data() {
    return {
      itemsCountries: [],
      itemsStates: [],
      screenWidth: 0,
      texts: '',
      bLoading: false,
      latitude: '',
      longitude: '',
      blueBook: '',
      description: '',
      email: '',
      address: '',
      city: '',
      zipCode: '',
      name: '',
      license: '',
      phoneNumber: '',
      state: '',
      country: '',
      taxPayerId: '',
    }
  },
  beforeMount() {
    this.texts = FILE.customerTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize", this.handleResize);
    this.close();
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    editCustomer() {
      this.bLoading = true;

      const params = {
        sName: this.name,
        sEmail: this.email,
        sDescription: this.description,
        sPhoneNumber: this.phoneNumber,
        sLocationAddress: this.address,
        sLocationCity: this.city,
        sLocationStateId: this.state,
        sLocationZipCode: this.zipCode,
        dLocationLatitude: this.latitude,
        dLocationLongitude: this.longitude,
        sPACALicenseNumber: this.license,
        sTaxpayerId: this.taxPayerId,
        sBlueBookId: this.blueBook
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/customer_enterprises/${this.$store.state.arrEditCustomer.sCustomerEnterpriseId}`,
        params,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    close() {
      this.$store.commit('setDialogEditCustomer', {
        active: false,
        arr: []
      });
      this.latitude = '';
      this.longitude = '';
      this.blueBook = '';
      this.description = '';
      this.email = '';
      this.address = '';
      this.city = '';
      this.zipCode = '';
      this.name = '';
      this.license = '';
      this.phoneNumber = '';
      this.state = '';
      this.country = '';
      this.taxPayerId = '';
    },
    getCountries() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {

        },
      })
        .then((response) => {
          this.itemsCountries = response.data.results;
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    selecState() {
      this.state = "";
      this.getStates();
    },
    getStates() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.country}/states`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {

        },
      })
        .then((response) => {
          this.itemsStates = response.data.results;
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    }
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogEditCustomer() {
      return this.$store.state.dialogEditCustomer;
    },
    validateForm() {
      return (
        this.latitude !== '' &&
        this.longitude !== '' &&
        this.blueBook !== '' &&
        this.description !== '' &&
        this.email !== '' &&
        this.address !== '' &&
        this.city !== '' &&
        this.zipCode !== '' &&
        this.name !== '' &&
        this.license !== '' &&
        this.phoneNumber !== '' &&
        this.state !== '' &&
        this.taxPayerId !== '' &&
        this.latitude !== null &&
        this.longitude !== null &&
        this.blueBook !== null &&
        this.description !== null &&
        this.email !== null &&
        this.address !== null &&
        this.city !== null &&
        this.zipCode !== null &&
        this.name !== null &&
        this.license !== null &&
        this.phoneNumber !== null &&
        this.state !== null &&
        this.taxPayerId !== null
      )
    }
  },
  watch: {
    dialogEditCustomer: function () {
      if (this.dialogEditCustomer) {
        this.texts = FILE.customerTexts[this.selectLanguage];
        this.latitude = this.$store.state.arrEditCustomer.dLocationLatitude;
        this.longitude = this.$store.state.arrEditCustomer.dLocationLongitude;
        this.blueBook = this.$store.state.arrEditCustomer.sBlueBookId;
        this.description = this.$store.state.arrEditCustomer.sDescription;
        this.email = this.$store.state.arrEditCustomer.sEmail;
        this.address = this.$store.state.arrEditCustomer.sLocationAddress;
        this.city = this.$store.state.arrEditCustomer.sLocationCity;
        this.zipCode = this.$store.state.arrEditCustomer.sLocationZipCode;
        this.name = this.$store.state.arrEditCustomer.sName;
        this.license = this.$store.state.arrEditCustomer.sPACALicenseNumber;
        this.phoneNumber = this.$store.state.arrEditCustomer.sPhoneNumber;
        this.state = this.$store.state.arrEditCustomer.sStateId;
        this.country = this.$store.state.arrEditCustomer.sCountryId;
        this.taxPayerId = this.$store.state.arrEditCustomer.sTaxpayerId;

        this.getCountries();
        this.getStates();
      }
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.customerTexts[this.selectLanguage];
      }
    }
  }
}